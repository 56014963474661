import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white py-6 border-t border-gray-200 shadow-md">
      <div className="max-w-7xl mx-auto px-4 text-center text-gray-600">
        <p>© 2024 MJM Photo. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;