
import React from 'react';
import { Helmet } from 'react-helmet';

const Success = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 py-12">
      <Helmet>
        <title>Form Submitted Successfully - MJM Photography, Inc.</title>
      </Helmet>
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg text-center">
        <h1 className="text-2xl font-bold mb-6">Thank You!</h1>
        <p className="mb-6 text-gray-700">
          Your message has been sent successfully. We will get back to you shortly.
        </p>
        <a href="/" className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
          Return
        </a>
      </div>
    </div>
  );
};

export default Success;