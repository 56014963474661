import React, { useState } from 'react';
import { Helmet} from 'react-helmet';
import './../css/GraduationPortraits.css';
import img1 from './../pics/TraditionalPortraits/01.jpg';
import img2 from './../pics/TraditionalPortraits/02.jpg';
import img3 from './../pics/TraditionalPortraits/03.jpg';
import img4 from './../pics/TraditionalPortraits/04.jpg';
import img5 from './../pics/TraditionalPortraits/05.jpg';
import img6 from './../pics/TraditionalPortraits/06.jpg';
import img7 from './../pics/TraditionalPortraits/07.jpg';
import img8 from './../pics/TraditionalPortraits/08.jpg';

const TraditionalPortraits = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const portraits = [
    {
      type: 'traditional',
      image: img1,
    },
    {
      type: 'traditional',
      image: img2,
    },
    {
      type: 'traditional',
      image: img3,
    },
    {
      type: 'traditional',
      image: img4,
    },
    {
      type: 'traditional',
      image: img5,
    },
    {
      type: 'traditional',
      image: img6,
    },
    {
      type: 'traditional',
      image: img7,
    },
    {
      type: 'traditional',
      image: img8,
    }
  ];

  const openLightbox = (index) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const nextImage = (e) => {
    e.stopPropagation();
    setCurrentImage((currentImage + 1) % portraits.length);
  };

  const prevImage = (e) => {
    e.stopPropagation();
    setCurrentImage((currentImage - 1 + portraits.length) % portraits.length);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <Helmet>
        <title>Traditional Portraits | MJM Photography</title>
      </Helmet>
      {/* Main Content */}
      <main className="flex-grow max-w-7xl mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold text-center text-gray-900 mb-12">
        Traditional Portraits
        </h1>

        <div className="space-y-6 text-center max-w-4xl mx-auto">
        <p className="text-gray-700 leading-relaxed">
        Traditional head and shoulder portraits are the foundation of what we do. 
        A uniform and consistent traditional portrait makes for a great looking yearbook 
        and a timeless image of each person we photograph.
        </p>
        </div>

        {/* Spacer */}
        <div className="my-8"></div>

        {/* Portrait Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-12">
          {portraits.map((portrait, index) => (
            <div key={index} className="bg-white rounded-lg overflow-hidden shadow-3xl">
              <img
                src={portrait.image}
                alt={portrait.alt}
                className="w-full h-auto object-cover aspect-w-3 aspect-h-4 cursor-pointer shadow-2xl hover:shadow-3xl transition-shadow duration-200"
                onClick={() => openLightbox(index)}
                style={{ width: '212px', height: '260px' }}
              />
            </div>
          ))}
        </div>

        {/* Lightbox */}
        {lightboxOpen && (
          <div className="lightbox" onClick={closeLightbox}>
            <div className="lightbox-content">
              <img src={portraits[currentImage].image} alt={portraits[currentImage].alt} className="lightbox-image" />
              <div className="lightbox-controls">
                <button className="lightbox-control prev" onClick={prevImage}>‹</button>
                <button className="lightbox-control next" onClick={nextImage}>›</button>
              </div>
            </div>
          </div>
        )}

        {/* Company Description */}
        
      </main>
    </div>
  );
};

export default TraditionalPortraits;